import {
  DiscountType,
  PackagedBeerDetailsContainer,
  RecallType,
} from "../../gql/hooks";
import {
  ProductConditionFormType,
  ProductConditionType,
} from "../../redux/slices/proposal/types/productConditionTypes";

// Classic proposal commercial conditions
export function constructProductsConditionByContainer(
  bottlesIds: number[],
  kegsIds: number[],
  commercialValues: ProductConditionType[],
  isPending?: boolean,
) {
  const kegProductConditionForms: ProductConditionFormType[] = kegsIds.map(
    (productId) => {
      const commercialValue = commercialValues.find(
        (_) =>
          _.productId === productId &&
          _.container === PackagedBeerDetailsContainer.Keg,
      );
      return {
        isPending,
        productId,
        formId: `${
          PackagedBeerDetailsContainer.Keg
        }-${productId}-${Date.now()}`,
        commercialConditions: {
          container: PackagedBeerDetailsContainer.Keg,
          discountType:
            commercialValue?.discountType || DiscountType.Percentage,
          discountValue: commercialValue?.discountValue
            ? String(commercialValue.discountValue)
            : undefined,
          discountX: commercialValue?.discountX
            ? String(commercialValue.discountX)
            : undefined,
          discountY: commercialValue?.discountY
            ? String(commercialValue.discountY)
            : undefined,
          recallType: commercialValue?.recallType || RecallType.LiterRecall,
          recallValue: commercialValue?.recallValue
            ? String(commercialValue.recallValue)
            : undefined,
          amortizationType:
            commercialValue?.amortizationType || RecallType.EuroPerLiterLoan,
          amortizationValue: commercialValue?.amortizationValue
            ? String(commercialValue.amortizationValue)
            : undefined,
          packagedProductId: commercialValue?.packagedProductId,
          packagedProductUnitNb: String(
            commercialValue?.packagedProductUnitNb || "",
          ),
          freePackagedProductUnitsNb: String(
            commercialValue?.freePackagedProductUnitsNb || "",
          ),
        },
      };
    },
  );

  const bottleProductConditionForms: ProductConditionFormType[] =
    bottlesIds.map((productId) => {
      const commercialValue = commercialValues.find(
        (_) =>
          _.productId === productId &&
          _.container === PackagedBeerDetailsContainer.Bottle,
      );
      return {
        isPending,
        productId,
        formId: `${
          PackagedBeerDetailsContainer.Bottle
        }-${productId}-${Date.now()}`,
        commercialConditions: {
          container: PackagedBeerDetailsContainer.Bottle,
          discountType:
            commercialValue?.discountType || DiscountType.Percentage,
          discountValue: commercialValue?.discountValue
            ? String(commercialValue.discountValue)
            : undefined,
          discountX: commercialValue?.discountX
            ? String(commercialValue.discountX)
            : undefined,
          discountY: commercialValue?.discountY
            ? String(commercialValue.discountY)
            : undefined,
          recallType: commercialValue?.recallType || RecallType.LiterRecall,
          recallValue: commercialValue?.recallValue
            ? String(commercialValue.recallValue)
            : undefined,
          amortizationType:
            commercialValue?.amortizationType || RecallType.EuroPerLiterLoan,
          amortizationValue: commercialValue?.amortizationValue
            ? String(commercialValue.amortizationValue)
            : undefined,
          packagedProductId: commercialValue?.packagedProductId,
          packagedProductUnitNb: String(
            commercialValue?.packagedProductUnitNb || "",
          ),
          freePackagedProductUnitsNb: String(
            commercialValue?.freePackagedProductUnitsNb || "",
          ),
        },
      };
    });

  return [...kegProductConditionForms, ...bottleProductConditionForms];
}

// Short proposal commercial conditions
export function constructProductsCommercialCondition(productIds?: number[]) {
  if (!productIds) return [];
  return productIds.map((productId) => ({
    productId: productId,
    formId: `${productId}`,
    commercialConditions: {
      discountType: DiscountType.Percentage,
      recallType: RecallType.LiterRecall,
      amortizationType: RecallType.EuroPerLiterLoan,
      packagedProductUnitNb: "",
      freePackagedProductUnitsNb: "",
    },
  }));
}
